.chat-history--wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  min-width: 42vw;
  max-width: var(--size--chat-history);
  height: calc(86vh - 78px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: nowrap;
  overflow: visible;
  z-index: calc(var(--z-index--ontop) - 1);

  --scale: 1;
  mask-image:
    linear-gradient(to top, black 0%, white 18%),
    linear-gradient(to bottom, white 86%, black 100%);
  
  .chat-history--inner {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: auto;
    overflow-x: visible;
    padding-top: calc(58vh - 86px);
    padding-bottom: calc(86vh - 86px - 58vh);
    padding-right: var(--spacing--l);
    padding-left: var(--spacing--l);
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
  }

  .chat-history--inner > .chat-bubble--wrapper:nth-child(1) {
    margin-top: auto !important;
  }
}

@media screen and (max-width: 767px) {
  .chat-history--wrapper {
    max-width: 100vw;
    min-width: 100vw;
    top: 126px;
    height: calc(100vh - 68px - 126px);
  }
}