:root {
  --input--wrapper--height: 86px;
  --input--wrapper--height--mobile: 78px;
  --input--inner-button--width: 54px;
  --input--inner-button--height: var(--input--inner-button--width);
  --input--inner-button--width--mobile: 42px;
  --input--inner-button--height--mobile: var(--input--inner-button--width--mobile);
  --input--inner-button--spacing: calc((var(--input--wrapper--height) - var(--input--inner-button--width))/2);
  --input--inner--padding-right: calc(var(--spacing--m) + var(--input--inner-button--width)*1 + var(--spacing--s));
  --input--inner--padding-right--mobile: calc(var(--spacing--s) + var(--input--inner-button--width--moible)*1 + var(--spacing--s));
}

.input--wrapper {
  position: fixed;
  display: flex;
  top: calc(86vh - var(--input--wrapper--height));
  height: var(--input--wrapper--height);
  width: 100vw;
  pointer-events: none;
  justify-content: center;
  transition: ease-in top 0.26s, ease-out all 0s;
  box-sizing: border-box;
  z-index: calc(var(--z-index--ontop) + 1);
  
  .input--inner {
    position: relative;
    overflow: hidden;
    pointer-events: auto;
    width: max(calc(100vw - var(--spacing--l)*2), 676px);
    max-width: 716px;
    height: 100%;
    margin-left: var(--spacing--l);
    margin-right: var(--spacing--l);
    backdrop-filter: blur(9px);
    background-color: var(--color--brown--50);
    background: var(--color--brown--gradient--50);
    box-shadow: 0px -8px var(--box-shadow-spread--medium) var(--color--box-shadow--10), 0px -1px var(--box-shadow-spread--tiny) var(--color--box-shadow--10);

    form {
      width: 100%;
      height: 100%;
    }
    
    input.input--user-input {
      margin: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      padding: var(--spacing--m) var(--input--inner--padding-right) var(--spacing--m) var(--spacing--l);
      box-sizing: border-box;
      background: transparent;
      color: var(--color--text--light);
      border: 1px solid var(--color--beige);
    }

    input.input--user-input::placeholder {
      font-style: italic;
      color: var(--color--brown--secondary);
    }

    input.input--user-input:focus, 
    input.input--user-input:focus-visible {
      border: 1px solid var(--color--red);
    }
  }

  .input--mic,
  .input--submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--input--inner-button--width);
    height: var(--input--inner-button--height);
    border-radius: 32px;
    position: absolute;
    color: var(--color--black);
    z-index: var(--z-index--float);
    top: var(--input--inner-button--spacing);
    right: var(--input--inner-button--spacing);
  }

  .input--mic {
    display: none;
    right: calc(var(--input--inner-button--spacing) + var(--input--inner-button--width) + var(--spacing--s));
  }
}

.input--wrapper.hidden {
  top: calc(100vh + 86px);
  transition: ease-out top 0.26s;
}

.input--wrapper.prompt .input--inner {
  animation: loud 0.2s forwards;
}

@media screen and (max-width: 767px) {
  .input--wrapper {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    height: var(--input--wrapper--height--mobile);

    .input--inner {
      width: 100%;
      max-width: none;
      margin: 0;
    }

    input.input--user-input {
      padding: var(--spacing--s) var(--input--inner--padding-right--mobile) var(--spacing--s) var(--spacing--m);
    }

    .input--mic,
    .input--submit {
      width: var(--input--inner-button--width--mobile);
      height: var(--input--inner-button--height--mobile);
    }
  }
}

@keyframes wiggle {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(4px, 5px) rotate(2deg);
  }
  50% {
    transform: translate(0, 0) rotate(0eg);
  }
  75% {
    transform: translate(-5px, 6px) rotate(-3deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}