.footer--wrapper {
  position: absolute;
  width: 100%;
  height: 24px;
  top: auto;
  bottom: var(--spacing--m);
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 var(--spacing--l);
  box-sizing: border-box;

  .footer--navigation {
    display: flex;
    flex-direction: row;

    a {
      font-size: var(--font-size--small);
      margin: 0 var(--spacing--sm);
      color: var(--color--beige);
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .footer--copyright {
    font-size: var(--font-size--small);
    color: var(--color--beige);
  }
}

@media screen and (max-width: 767px) {
  .footer--wrapper {
    top: 54px;
    bottom: auto;
    justify-content: flex-end;
    padding: 0 var(--spacing--s);

    .footer--navigation {
      text-align: right;
    }
  }

  .footer--copyright {
    display: none;
  }
}

@media screen and (max-width: 483px) {
  .footer--wrapper {
    top: 22px;
    
    .footer--navigation {
      flex-direction: column;

      a {
        margin: var(--spacing--s) var(--spacing--sm) var(--spacing--s) 0;
      }
    }
  }
}