::selection {
  text-shadow: none !important;
  color: #FCFCFC !important;
  background: rgba(235, 35, 48, 0.62);
}

:root {
  /*
   * 
   */
  --spacing--n: 2px;
  --spacing--t: 4px;
  --spacing--tn: calc(var(--spacing--t) + var(--spacing--n));
  --spacing--s: 8px;
  --spacing--sm: 12px;
  --spacing--m: 16px;
  --spacing--l: 32px;
  --spacing--xl: 48px;
  --spacing--xxl: 64px;

  /*
   *
   */
  --size--chat-history: 764px;

  /*
   *
   */
  --border-radius--chat-bubble: 27px;
  --border-radius--chat-bubble--mobile: 21px;
  --border-radius--user-input: 43px;

  /*
   *
   */
  --color--black: #242121;
  --color--white: #FBFBFB;
  --color--gray-100: #FBFBFB;
  --color--gray-200: #F2F2F2;
  --color--gray-300: #E5E0E0;
  --color--gray-400: #CECECE;
  --color--gray-500: #707070;
  --color--gray-600: #707070;
  --color--gray-700: #2B2525;
  --color--gray: var(--color--gray-400);
  --color--darkgray: var(--color--gray-700);
  --color--darkgray--gradient: linear-gradient(153deg, rgba(46,46,46,1) 0%, rgba(39,26,26,1) 100%);
  --color--beige: #D1C3A9;
  --color--brown: rgba(38,12,6, 1);
  --color--brown--50: rgba(38,12,6, 0.42);
  --color--brown--gradient: linear-gradient(-228deg,rgba(7,7,7,1) 18%,var(--color--brown) 82%);
  --color--brown--gradient--50: linear-gradient(-228deg,rgba(7,7,7,0.42) 18%,var(--color--brown--50) 82%);
  --color--brown--secondary: #8A7D65;
  --color--brown--secondary--gradient: linear-gradient(-228deg,#695d46 18%,var(--color--brown--secondary) 82%);
  --color--red: #EB2330;
  --color--red--hover: #E01422;
  --color--green: rgb(9, 129, 58);
  --color--green--alt-1: rgba(8,114,61,1);
  --color--green--alt-2: rgba(6,145,54,1);
  --color--green--gradient: linear-gradient(153deg, var(--color--green--alt-1) 0%, var(--color--green--alt-2) 100%);
  --color--green--gradient--flat: linear-gradient(153deg, var(--color--green) 0%, var(--color--green) 100%);
  --color--blue: rgba(52,65,224,1);
  --color--blue--alt-1: rgba(54,66,214,1);
  --color--blue--alt-2:  rgba(82,147,237,1);
  --color--blue--gradient: linear-gradient(153deg, var(--color--blue--alt-1) 0%, var(--color--blue--alt-2) 100%);;
  --color--blue--gradient--flat: linear-gradient(153deg, var(--color--blue) 0%, var(--color--blue) 100%);;
  --color--off: #0D0800;
  --color--text: #100e0b;
  --color--text--light: var(--color--gray-100);
  --color--focus: rgb(119, 172, 159);
  --color--box-shadow--100: #0D0800;
  --color--box-shadow--90: rgba(13, 8, 0, 0.92);
  --color--box-shadow--80: rgba(13, 8, 0, 0.81);
  --color--box-shadow--70: rgba(13, 8, 0, 0.72);
  --color--box-shadow--60: rgba(13, 8, 0, 0.63);
  --color--box-shadow--50: rgba(13, 8, 0, 0.56);
  --color--box-shadow--40: rgba(13, 8, 0, 0.42);
  --color--box-shadow--30: rgba(13, 8, 0, 0.29);
  --color--box-shadow--20: rgba(13, 8, 0, 0.17);
  --color--box-shadow--10: rgba(13, 8, 0, 0.08);

  /*
   *
   */
  --timing--regular: 420ms;

  /*
   *
   */
  --z-index--below: -1;
  --z-index--regular: 1;
  --z-index--float: 10;
  --z-index--ontop: 1000;
  --z-index--navigation: 9999;

  /*
   *
   */
  --font-size--small: 0.89rem;
  --font-size--regular: 1.2rem;
  --font-size--regular--mobile: 1.1rem;
  --font-size--h6: 1.2rem;
  --font-size--h5: 1.43rem;
  --font-size--h4: 1.43rem;
  --font-size--h3: 1.86rem;
  --font-size--h2: 2.4rem;
  --font-size--h1: 3.6rem;
  --font-size--chat: var(--font-size--regular);
  --font-size--chat--mobile: var(--font-size--regular--mobile);
  --font-family--gill-sans--regular: "gill-sans-nova";
  --font-weight--button--small: 400;
  --font-weight--button--regular: 600;
  --font-weight--button--large: 700;

  /*
   *
   */
  --padding-top--text: var(--spacing--s);

  /*
   *
   */
  --line-height--regular: calc(var(--font-size--regular) + var(--padding-top--text));

  /*
   *
   */
  --box-shadow-spread--tiny: 9px;
  --box-shadow-spread--small: 42px;
  --box-shadow-spread--medium: 56px;
  --box-shadow-spread--regular: 72px;
}