.scene--wrapper {
  position: fixed;
  height: calc(100vh + 2px);
  width: calc(100vw + 2px);
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  overflow: hidden;

  .scene--view {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
  
  .scene--controls {
    width: 100%;
    position: absolute;
    bottom: calc((67vh - 80vh) * -1);
    display: flex;
    flex-flow: row nowrap;
    overflow-y: visible;
    overflow-x: scroll;
    align-items: center;
    justify-content: center;
  }
  
  .scene--controls[data-skipped=false] {
    transform: translateY(0);
    transition: ease-out all .28s;
  }
  
  .scene--controls[data-skipped=true] {
    bottom: 0;
    transform: translateY(calc(max(100%, 13vh)));
    transition: ease-in all .23s;
  }
}