.background--sabotage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-image: url(../../assets/images/background--brown.jpg);
  background-size: 100% 100%;
  background-position: top center;
}