.logo--wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 150px;
  width: 150px;
  padding: var(--spacing--xl);

  .logo--image {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width: 767px) {
  .logo--wrapper {
    height: 92px;
    width: 92px;
    padding: var(--spacing--m);
  }
}