.chat-bubble--wrapper {
  opacity: 0;
  display: block;
  width: fit-content;
  margin: 0 auto var(--spacing--m);
  transform: translateY(calc(var(--spacing--xl) * -1));
  transition: ease-in-out all var(--timing--regular);
  align-self: center;
  overflow: visible;
  position: relative;
  
  .chat-bubble--inner {
    display: flex;
    flex: 1;
    min-width: calc(var(--border-radius--chat-bubble) * 2);
    max-width: 576px;
    padding: var(--spacing--l);
    border-radius: var(--border-radius--chat-bubble);
    border: 1px solid var(--color--gray-300);
    background: rgb(254,254,254);
    background: linear-gradient(0deg, rgb(254,254,254) 0%, var(--color--text--light) 100%);
    box-sizing: border-box;
    flex-flow: column nowrap;
  }

  p {
    margin: 0;
    line-height: var(--line-height--regular);
    font-size: var(--font-size--chat);
    color: var(--color--text);
    white-space: pre-wrap;
  }

  .chat-bubble--name {
    font-weight: 600;
    margin-right: 0.2em;
  }

  .chat-bubble--end {
    align-self: flex-end;
    margin-top: var(--spacing--m)
  }
}

.chat-bubble--wrapper.loud {
  .chat-bubble--inner {
    animation: loud 0.2s infinite;
  }
}

.chat-bubble--wrapper[data-visible=true] {
  opacity: 1;
  transform: translateY(0);
}

.chat-bubble--wrapper[data-speaker] {
  .chat-bubble--pseudo-shadow {display: none;}
}

.chat-bubble--wrapper[data-speaker]:not([data-speaker="assistant"]) {
  max-width: max(92%, calc(420px - var(--spacing--xl)));
  
  .chat-bubble--pseudo-shadow {
    z-index: var(--z-index--below);
    display: block;
    left: 50%;
    bottom: var(--spacing--m);
    transform: translate(-50%, -50%);
    position: absolute;
    width: 90%;
    height: 35%;
    background: var(--color--black);
    box-shadow: 0px -4px var(--box-shadow-spread--regular) var(--color--box-shadow--60);
    border-radius: var(--border-radius--chat-bubble);
  }
}

.chat-bubble--wrapper[data-speaker]:not([data-speaker="user"]) {
  .chat-bubble--pseudo-shadow {
    height: min(86%, var(--spacing--l));
    width: min(86%, var(--spacing--l));
    box-shadow: 0 0 var(--box-shadow-spread--small) var(--color--off);
    bottom: var(--spacing--s);
  }
}

.chat-bubble--wrapper[data-bubble-color="brown"] .chat-bubble--inner {
  background: var(--color--brown);
  background: var(--color--brown--secondary--gradient);
  border: none;

  p {
    color: var(--color--text--light);
  }
}

@media screen and (max-width: 767px) {
  .chat-bubble--wrapper {
    margin: 0 auto var(--spacing--s);
    
    .chat-bubble--inner {
      max-width: 420px;
      padding: calc(var(--spacing--m) + var(--spacing--s));
      border-radius: var(--border-radius--chat-bubble);
    }

    p {
      font-size: var(--font-size--chat--mobile);
    }
  }
}

@keyframes loud {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(4px, 5px) rotate(2deg);
  }
  50% {
    transform: translate(0, 0) rotate(0eg);
  }
  75% {
    transform: translate(-5px, 6px) rotate(-3deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}