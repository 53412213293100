.navigation--wrapper {
  width: 100%;
  position: absolute;
  z-index: var(--z-index--navigation);
  bottom: calc((86vh - 92vh) * -1);
  padding: var(--spacing--s) 0;
  display: flex;
  flex-flow: row nowrap;
  overflow-y: visible;
  overflow-x: scroll;
  align-items: center;
  justify-content: center;

  > button {
    margin-left: var(--spacing--s);
    margin-right: var(--spacing--s);
  }
}

.navigation--wrapper[data-visible=true],
[data-scene-running=false] .navigation--wrapper {
  transform: translateY(0);
  transition: ease-out all .28s;
}

.navigation--wrapper[data-visible=false],
[data-scene-running=true] .navigation--wrapper {
  bottom: 0;
  transform: translateY(calc(max(100%, 13vh)));
  transition: ease-in all .23s;
}